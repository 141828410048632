import React from "react";
//import Link from "gatsby-link";
import PageHeader from "../components/Common/PageHeader";
import "./aboutus.less";
//import Img from "gatsby-image";
//import { just_excerpt } from "../utils/textutil";
import Layout from "../components/layout"


const ContactusPage = ({ data }) => (
  <Layout>
  <div id="main-wrap">
    {" "}
    {/* Main Wrap  */}
    {/* Page Content  */}
    <div id="page-content" className="header-static">
      {/* Page Header  */}
      <PageHeader title="聯絡我們">
        <span />
      </PageHeader>
      {/* END Page Header  */}
      <div id="home-wrap" className="content-section fullpage-wrap">
        <div className="container">
          <div className="col-md-2" />
          <div className="col-md-8 padding-leftright-null">
            <div className=" padding-onlytop-lg padding-md-top padding-md-bottom-lg">
              {/* <h2 className="text-center margin-bottom-small">關於我們</h2> */}
              <p>
                安信通科技(澳門)有限公司(簡稱Singou)是澳門科技大學科硏團隊的硏究成果進行產業化而成立的商業實體，2015年6月獲得澳門南通信託投資發展有限公司(中銀集團下屬企業)天使輪投資，專注於身份識別技術、人工智能及機器人研發與生產製造。2017年8月「天機1號」機器人正式推出市場,具有人臉識別、語音對話、視頻監控、室內室外機動行走等功能,適用於會展服務、考勤簽到、酒店服務、物業安防等場景。獲得市場認可,供不應求。為加快產品量產,公司正在中山投資建設一個機器人透明工廠及產學研基地,打造一個面向服務機器人行業的高科技創新智能製造與硏發的示範基地。為加快市場拓展,夯實研發團隊,公司在深圳、濟南、珠海、德國法蘭克福等地設立有「工程技休中心」。
              </p>
              <p className="text-right">
              <img className="guard" alt="guard" src="assets/img/aboutus-guards.jpg"/>

              </p>
            </div>
          </div>
          <div className="col-md-2" />
        </div>
        <div className="secondary-background">
          <div className="container">
            {/* CTA */}
            <div className="row no-margin">
                <div className="text text-center">
                  <h2 className="small white slogan">
                  安信通只做最好的服務機器人                  </h2>
                </div>
            </div>
            {/* END CTA */}
          </div>
        </div>
        <div className="container">
          {/* Title */}
          <div className="row no-margin padding-lg">
            <div className="col-md-12 padding-leftright-null">
              <div className="text text-center padding-topbottom-null">
                <h2 className="margin-bottom-null left">
安信通年表                </h2>
              </div>
            </div>
          </div>
          {/* END Title */}
        </div>
        <div className="container">
          {/* Timeline */}
          <div className="row no-margin padding-onlybottom-lg">
            <div className="col-md-12 padding-leftright-null">
              
            </div>
          </div>
          {/* END Timeline */}
        </div>
        
      </div>
    </div>
    {/* END Page Content */}
    {/* Main Wrap  */}
  </div>
  </Layout>
);

export default ContactusPage;
